<template>
  <div class="side-navigation-wrapper" v-resize="setHeight">
    <v-container class="ma-0 pa-0" fluid>
      <v-row class="ma-0 pa-0">
        <v-col
          :cols="!showTertiary || $vuetify.breakpoint.xs ? 12 : panelColumns ? 10 : ''"
          class="ma-0 pa-0 content-area"
          :style="{ height: settingsHeight + 'px', 'overflow-y': 'auto' }"
          v-resize="setHeight" 
          :class="{ 'pr-4': showTertiary && !$vuetify.breakpoint.xs }"
        >
          <slot name="component"></slot>
        </v-col>

        <div 
          :class="panelColumns && showTertiary ? `${panelColumns}, col` : ''"
          class="ma-0 pa-0"
          v-if="showTertiary"
        >
          <hb-page-menu v-model="showTertiary">
            <v-list dense>
              <v-list-item-group v-model="selection" mandatory>
                <v-list-item v-for="item in menuItems" exact :key="item.title" :value="item.id">
                  {{ item.title }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </hb-page-menu>
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "RightSideNavigation",
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: () => this.menuItems?.[0]?.id,
    },
    showTertiary: {
      type: Boolean,
      default: true,
    },
    panelColumns: {
      type: String,
      default: ''
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    addLocalPropertySelectorHeight: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      selection: this.value,
      settingsHeight: 0
    };
  },
  watch: {
    /**
     * Watches selection value to emit the value for v-model.
     */
    selection: {
      immediate: true,
      handler(value) {
        this.$emit("input", value);
      },
    },
  },
  components: {},
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
    }),
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
  },
  methods: {
    setHeight() {
      let fixedComponents = 276
      let headerHeight = this.hasHeader ? window.innerWidth < 1870 ? 25 : 0 : 0;
      let propertySelector = this.addLocalPropertySelectorHeight && this.showLocalPropertySelector ? -20 : 62;
      this.settingsHeight =
        window.innerHeight -
        headerHeight -
        fixedComponents +
        propertySelector
    },
  },
};
</script>
<style lang="scss" scoped>
.side-navigation-wrapper {
  .content-area {
    min-height: 60vh;
    min-height: 60svh;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #F1F1F1;
    }
    &::-webkit-scrollbar-thumb {
      background: #C1C1C1;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #A8A8A8;
    }
  }
}
.v-list-item--active {
  font-weight: 500;
}
</style>